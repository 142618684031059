import { refreshToken } from "@queries/refreshToken";
import { BASE_CONFIG } from "@config/baseConfig";
import axios from "axios";
import { toast } from "sonner";
import { deleteItem, getItem, setItem } from "@utils/localStorage";
import { userLogout } from "@utils/userLogout";
import { isServer } from "@tanstack/react-query";

const clientAxios = axios.create({
  baseURL: `${BASE_CONFIG.base_backend_url}/`,
});

const serverAxios = axios.create({
  baseURL: `${process.env.BACKEND_URL}/`,
});

export const client = () => (isServer ? serverAxios : clientAxios);

clientAxios.defaults.headers.common["Access-Control-Allow-Headers"] =
  "x-datadog-trace-id, x-datadog-parent-id, x-datadog-origin, x-datadog-sampling-priority";
serverAxios.defaults.headers.common["Access-Control-Allow-Headers"] =
  "x-datadog-trace-id, x-datadog-parent-id, x-datadog-origin, x-datadog-sampling-priority";

clientAxios.interceptors.request.use(
  (config) => {
    if (process.env.NEXT_PUBLIC_API_KEY && process.env.NEXT_PUBLIC_ORG_CODE) {
      config.headers["x-api-key"] = process.env.NEXT_PUBLIC_API_KEY;
      config.headers["x-org-code"] = process.env.NEXT_PUBLIC_ORG_CODE;
    }

    let access_token = getItem("access_token");

    if (access_token && !config.headers.Authorization) {
      config.headers.Authorization = `Bearer ${access_token}`;
    }

    return config;
  },
  (error) => {
    return error;
  },
);
serverAxios.interceptors.request.use(
  (config) => {
    if (process.env.NEXT_PUBLIC_API_KEY && process.env.NEXT_PUBLIC_ORG_CODE) {
      config.headers["x-api-key"] = process.env.NEXT_PUBLIC_API_KEY;
      config.headers["x-org-code"] = process.env.NEXT_PUBLIC_ORG_CODE;
    }

    let access_token = getItem("access_token");

    if (access_token && !config.headers.Authorization) {
      config.headers.Authorization = `Bearer ${access_token}`;
    }

    return config;
  },
  (error) => {
    return error;
  },
);

clientAxios.interceptors.response.use(
  (response) => response,
  async (error) => {
    console.info("Custom Error: ", error);
    const originalRequest = error.config;

    if (!error.response) return;

    const errorMessage = error.response.data?.error?.type as string;
    const errorCode = error.response.data?.error?.code;
    const errorValidationMessage: string =
      error.response.data.error.messages?.jwt ?? "";

    if (["METHOD_NOT_ALLOWED"].includes(errorCode)) {
      toast.error("You are unable to make this action");
    }

    if (error.response.status === 500) {
      userLogout();
      return Promise.reject(error);
    }

    // TODO - Change interceptor for authentication errors.

    if (
      errorMessage?.includes("Not enough segments") &&
      error.response.status === 422
    ) {
      deleteItem("access_token");
    }

    if (
      (["AUTHENTICATION_ERROR"].includes(errorMessage) ||
        errorValidationMessage.toLowerCase() === "invalid token") &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      const refresh_token = getItem("refresh_token");

      if (refresh_token) {
        setItem("access_token", refresh_token);
        deleteItem("refresh_token");

        await refreshToken(refresh_token);
      } else {
        userLogout();
        location.replace("/");
      }

      return client()(originalRequest);
    }

    if (originalRequest._retry) {
      userLogout();
      location.replace("/");
    }

    return Promise.reject(error);
  },
);
serverAxios.interceptors.response.use(
  (response) => response,
  async (error) => {
    console.info("Custom Error: ", error);
    const originalRequest = error.config;

    if (!error.response) return;

    const errorMessage = error.response.data?.error?.type as string;
    const errorCode = error.response.data?.error?.code;
    const errorValidationMessage: string =
      error.response.data.error.messages?.jwt ?? "";

    if (["METHOD_NOT_ALLOWED"].includes(errorCode)) {
      toast.error("You are unable to make this action");
    }

    if (error.response.status === 500) {
      userLogout();
      return Promise.reject(error);
    }

    // TODO - Change interceptor for authentication errors.

    if (
      errorMessage?.includes("Not enough segments") &&
      error.response.status === 422
    ) {
      deleteItem("access_token");
    }

    if (
      (["Signature verification failed"].includes(errorMessage) ||
        errorValidationMessage.toLowerCase() === "invalid token") &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      const refresh_token = getItem("refresh_token");

      if (refresh_token) {
        setItem("access_token", refresh_token);
        deleteItem("refresh_token");

        await refreshToken(refresh_token);
      } else {
        userLogout();
      }

      return client()(originalRequest);
    }

    if (originalRequest._retry) {
      userLogout();
      location.replace("/");
    }

    return Promise.reject(error);
  },
);
