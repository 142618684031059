import React from "react";
import { tv, VariantProps } from "tailwind-variants";

//add color
//add letter-spacing

const baseTypography = tv({
  variants: {
    tracking: {
      tighter: "-tracking-[0.03em]",
      tight: "-tracking-[0.02em]",
      normal: "tracking-normal",
      wide: "tracking-[0.02em]",
      wider: "tracking-[0.03em]",
    },
    size: {
      "2xl": "",
      xl: "",
      lg: "",
      md: "",
      sm: "",
      xs: "",
      xxs: "",
    },
    weight: {
      normal: "font-normal",
      medium: "font-medium",
      semibold: "font-semibold",
      bold: "font-bold",
    },
  },
});

const bodyTypography = tv(
  {
    extend: baseTypography,
    variants: {
      size: {
        "2xl": "text-[1.5rem] leading-[2rem]",
        xl: "text-[1.25rem] leading-[1.5rem]",
        lg: "text-[1.125rem] leading-[1.5rem]",
        md: "text-[1rem] leading-[1.5rem]",
        sm: "text-[0.875rem] leading-[1.25rem]",
        xs: "text-[0.75rem] leading-[1.125rem]",
        xxs: "text-[0.625rem] leading-[0.75rem]",
      },
    },
  },
  {
    responsiveVariants: true, // `true` to apply to all screen sizes
  },
);

const displayTypography = tv(
  {
    extend: baseTypography,
    variants: {
      size: {
        "2xl": "text-[4.5rem] leading-[5.5rem] -tracking-[0.02rem]",
        xl: "text-[3.5rem] leading-[4.5rem] -tracking-[0.0215em]",
        lg: "text-[3rem] leading-[3.75rem] -tracking-[0.02em]",
        md: "text-[2.25rem] leading-[2.75rem] -tracking-[0.02em]",
        sm: "text-[2rem] leading-[2.5rem]",
        xs: "text-[1.75rem] leading-[2.25rem]",
        xxs: "text-[1.5rem] leading-[2rem]",
      },
    },
  },
  {
    responsiveVariants: true, // `true` to apply to all screen sizes
  },
);

const defaultComponent: Record<
  "display" | "body",
  Record<
    Required<VariantProps<typeof baseTypography>>["size"],
    React.ElementType
  >
> = {
  display: {
    "2xl": "h1",
    xl: "h2",
    lg: "h3",
    md: "h4",
    sm: "h5",
    xs: "h6",
    xxs: "p",
  },
  body: {
    "2xl": "h1",
    xl: "h2",
    lg: "h3",
    md: "p",
    sm: "p",
    xs: "label",
    xxs: "small",
  },
} as const;

const defaultResponsiveSizes: Record<
  Required<VariantProps<typeof baseTypography>>["size"],
  Required<VariantProps<typeof bodyTypography>>["size"]
> = {
  "2xl": {
    initial: "lg",
    sm: "xl",
    md: "2xl",
  },
  xl: {
    initial: "md",
    sm: "lg",
    md: "xl",
  },
  lg: {
    initial: "sm",
    sm: "md",
    md: "lg",
  },
  md: {
    initial: "xs",
    sm: "sm",
    md: "md",
  },
  sm: {
    initial: "xs",
    md: "sm",
  },
  xs: {
    initial: "xs",
  },
  xxs: {
    initial: "xxs",
  },
};

interface BaseTypographyProps extends VariantProps<typeof baseTypography> {}

export interface TypographyProps<Component extends React.ElementType>
  extends BaseTypographyProps {
  type: "body" | "display";
  children: React.ReactNode;
  className?: string;
  component?: Component;
  variantMapping?: typeof defaultComponent;
  responsiveSize?: Record<string, BaseTypographyProps>;
}

function Typography<Component extends React.ElementType = "p">({
  children,
  className,
  component,
  responsiveSize,
  size = "2xl",
  tracking = "normal",
  type = "body",
  variantMapping = defaultComponent,
  weight = "normal",
  ...rest
}: TypographyProps<Component> & React.ComponentPropsWithoutRef<Component>) {
  const AsComponent: React.ElementType =
    component! ?? variantMapping[type][size];
  const sizes = responsiveSize ?? defaultResponsiveSizes;
  const currentVariant =
    type === "body"
      ? // @ts-expect-error size
        bodyTypography({ size: sizes[size], weight, className, tracking })
      : // @ts-expect-error size
        displayTypography({ size: sizes[size], weight, className, tracking });

  return (
    <AsComponent className={currentVariant} {...rest}>
      {children}
    </AsComponent>
  );
}

export default Typography;

/* 
<div className="grid grid-cols-4 gap-4">
        <Typography type="display">Display 2xl</Typography>
        <Typography type="display" weight="medium">
          Display 2xl
        </Typography>
        <Typography type="display" weight="semibold">
          Display 2xl
        </Typography>
        <Typography type="display" weight="bold">
          Display 2xl
        </Typography>

        <Typography type="display" size="xl">
          Display xl
        </Typography>
        <Typography type="display" size="xl" weight="medium">
          Display xl
        </Typography>
        <Typography type="display" size="xl" weight="semibold">
          Display xl
        </Typography>
        <Typography type="display" size="xl" weight="bold">
          Display xl
        </Typography>

        <Typography type="display" size="lg">
          Display lg
        </Typography>
        <Typography type="display" size="lg" weight="medium">
          Display lg
        </Typography>
        <Typography type="display" size="lg" weight="semibold">
          Display lg
        </Typography>
        <Typography type="display" size="lg" weight="bold">
          Display lg
        </Typography>

        <Typography type="display" size="md">
          Display md
        </Typography>
        <Typography type="display" size="md" weight="medium">
          Display md
        </Typography>
        <Typography type="display" size="md" weight="semibold">
          Display md
        </Typography>
        <Typography type="display" size="md" weight="bold">
          Display md
        </Typography>

        <Typography type="display" size="sm">
          Display sm
        </Typography>
        <Typography type="display" size="sm" weight="medium">
          Display sm
        </Typography>
        <Typography type="display" size="sm" weight="semibold">
          Display sm
        </Typography>
        <Typography type="display" size="sm" weight="bold">
          Display sm
        </Typography>

        <Typography type="display" size="xs">
          Display xs
        </Typography>
        <Typography type="display" size="xs" weight="medium">
          Display xs
        </Typography>
        <Typography type="display" size="xs" weight="semibold">
          Display xs
        </Typography>
        <Typography type="display" size="xs" weight="bold">
          Display xs
        </Typography>

        <Typography type="display" size="xxs">
          Display xxs
        </Typography>
        <Typography type="display" size="xxs" weight="medium">
          Display xxs
        </Typography>
        <Typography type="display" size="xxs" weight="semibold">
          Display xxs
        </Typography>
        <Typography type="display" size="xxs" weight="bold">
          Display xxs
        </Typography>

        <Typography type="body">Body 2xl</Typography>
        <Typography type="body" weight="medium">
          Body 2xl
        </Typography>
        <Typography type="body" weight="semibold">
          Body 2xl
        </Typography>
        <Typography type="body" weight="bold">
          Body 2xl
        </Typography>

        <Typography type="body" size="xl">
          Body xl
        </Typography>
        <Typography type="body" size="xl" weight="medium">
          Body xl
        </Typography>
        <Typography type="body" size="xl" weight="semibold">
          Body xl
        </Typography>
        <Typography type="body" size="xl" weight="bold">
          Body xl
        </Typography>

        <Typography type="body" size="lg">
          Body lg
        </Typography>
        <Typography type="body" size="lg" weight="medium">
          Body lg
        </Typography>
        <Typography type="body" size="lg" weight="semibold">
          Body lg
        </Typography>
        <Typography type="body" size="lg" weight="bold">
          Body lg
        </Typography>

        <Typography type="body" size="md">
          Body md
        </Typography>
        <Typography type="body" size="md" weight="medium">
          Body md
        </Typography>
        <Typography type="body" size="md" weight="semibold">
          Body md
        </Typography>
        <Typography type="body" size="md" weight="bold">
          Body md
        </Typography>

        <Typography type="body" size="sm">
          Body sm
        </Typography>
        <Typography type="body" size="sm" weight="medium">
          Body sm
        </Typography>
        <Typography type="body" size="sm" weight="semibold">
          Body sm
        </Typography>
        <Typography type="body" size="sm" weight="bold">
          Body sm
        </Typography>

        <Typography type="body" size="xs">
          Body xs
        </Typography>
        <Typography type="body" size="xs" weight="medium">
          Body xs
        </Typography>
        <Typography type="body" size="xs" weight="semibold">
          Body xs
        </Typography>
        <Typography type="body" size="xs" weight="bold">
          Body xs
        </Typography>

        <Typography type="body" size="xxs">
          Body xxs
        </Typography>
        <Typography type="body" size="xxs" weight="medium">
          Body xxs
        </Typography>
        <Typography type="body" size="xxs" weight="semibold">
          Body xxs
        </Typography>
        <Typography type="body" size="xxs" weight="bold">
          Body xxs
        </Typography>
      </div>
*/
