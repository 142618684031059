import { client } from "@utils/axios-utils";
import { setItem } from "@utils/localStorage";
import { userLogout } from "@utils/userLogout";
import { GetAccessTokenResponse } from "@queries/getAccessToken";

export const refreshToken = (refresh_token: JWT) =>
  client()
    .post<GetAccessTokenResponse>(
      "v2/auth/refresh",
      {},
      {
        headers: {
          Authorization: `Bearer ${refresh_token}`,
        },
      },
    )
    .then((response) => {
      setItem("access_token", response.data.data.access_token);
      setItem("refresh_token", response.data.data.refresh_token);
    })
    .catch((err) => {
      userLogout();
    });
