"use client";
import { extendVariants, Button } from "@nextui-org/react";

export const MyButton = extendVariants(Button, {
  variants: {
    // <- modify/add variants
    color: {
      primary:
        "bg-[rgb(var(--button-color-primary-default-background))] text-[rgb(var(--button-color-primary-default-text))] shadow-button-main",
      secondary:
        "border border-[rgb(var(--button-color-secondary-default-border))] bg-[rgb(var(--button-color-secondary-default-background))] text-[rgb(var(--button-color-secondary-default-text))] shadow-button-main",
      navLink: " text-[rgb(var(--media-gray-500))]",
      activeNavLink:
        "border border-[rgb(var(--media-gray-200))] bg-[rgb(var(--media-white))] shadow-active-nav-link text-[rgb(var(--media-default))]",
    },
    isDisabled: {
      true: "bg-[#eaeaea] text-[#000] opacity-50 cursor-not-allowed",
    },
    size: {
      xs: "px-2 min-w-12 h-6 text-tiny gap-1 rounded-small",
      md: "px-3 min-w-20 h-10 typography-body-sm-medium gap-2 rounded-small",
      lg: "px-[1.125rem] min-w-14 h-10 typography-button-lg-medium gap-2 rounded-md",
      xl: "px-8 min-w-28 h-14 text-large gap-4 rounded-medium",
    },
    isIconOnly: {
      true: "!min-w-0 !w-auto",
    },
  },
  defaultVariants: {
    // <- modify/add default variants
    color: "navLink",
    size: "md",
  },
  compoundVariants: [
    // <- modify/add compound variants
    {
      isDisabled: true,
      color: "navLink",
      class: "bg-[#84cc16]/80 opacity-100",
    },
    {
      isIconOnly: "true",
      color: "secondary",
      class: "px-0 !gap-0",
    },
  ],
});
