import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import React from "react";
import { ModalProps } from "@nextui-org/modal";
import { MyButton } from "@components/Button";

interface BaseModalInterface extends Omit<ModalProps, "children"> {
  children?: React.ReactNode;
  primaryCallbackTitle: string;
  primaryCallback: () => void;
  secondaryCallbackTitle?: string;
  secondaryCallback?: () => void;
  title: string;
  body: React.ReactNode | string;
}

const BaseModal = ({
  primaryCallback,
  secondaryCallback,
  title,
  body,
  primaryCallbackTitle,
  secondaryCallbackTitle,
  ...rest
}: BaseModalInterface) => {
  return (
    <Modal isOpen={rest.isOpen} onOpenChange={rest.onOpenChange} {...rest}>
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1">{title}</ModalHeader>
            <ModalBody>{body}</ModalBody>
            <ModalFooter>
              {secondaryCallbackTitle && secondaryCallback && (
                <Button
                  color="danger"
                  variant="light"
                  onPress={secondaryCallback}
                >
                  {secondaryCallbackTitle}
                </Button>
              )}
              <MyButton
                color="primary"
                onPress={() => {
                  onClose();
                  primaryCallback();
                }}
              >
                {primaryCallbackTitle}
              </MyButton>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default BaseModal;
