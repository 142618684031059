import { deleteItem } from "./localStorage";

export const userLogout = () => {
  deleteItem("access_token");
  deleteItem("refresh_token");
  deleteItem("role");
  /*  
  deleteItem("federated_access_token");
  deleteItem("phone_number");
  deleteItem("name");
  deleteItem("name_id");
  deleteItem("persona_id"); */
};
