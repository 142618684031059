import { client } from "@utils/axios-utils";
import { getItem } from "@utils/localStorage";
import * as Yup from "yup";
import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

export interface requestContentUploadAndCreationResponse {
  data: {
    content_id: string;
  };
  requested_on: Date;
}

interface DefaultErrorResponse {}

export const requestContentCreationAndUpload = async () => {
  const response = await client().post<
    requestContentUploadAndCreationResponse,
    AxiosResponse<requestContentUploadAndCreationResponse>
  >("v2/content/retool/create-content-upload");

  return response.data;
};

export const requestContentCreationAndUploadMutationKey = [
  "REQUEST_CONTENT_UPLOAD_CREATION",
];

export const useRequestContentUploadAndCreationMutation = (
  options?: Partial<
    Omit<
      UseMutationOptions<
        requestContentUploadAndCreationResponse,
        DefaultErrorResponse
      >,
      "mutationKey" | "mutationFn"
    >
  >,
) =>
  useMutation({
    mutationKey: requestContentCreationAndUploadMutationKey,
    mutationFn: requestContentCreationAndUpload,
    ...options,
  });
